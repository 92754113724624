import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldLabelDirective } from './field-label.directive';
import { FieldInputDirective } from './field-input.directive';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FieldLabelDirective, FieldInputDirective, FormFieldComponent],
  exports: [FieldLabelDirective, FieldInputDirective, FormFieldComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class FormControlsModule { }
