import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Moduels as CustomModules } from './modules';
import { Components } from './components';
import { Pipes } from './pipes';


const Modules = [
  FormsModule,
  ReactiveFormsModule,
  ...CustomModules

];

@NgModule({
  declarations: [
    ...Components,
    ...Pipes
  ],
  imports: [
    CommonModule,
    ...Modules,
    HttpClientModule
    
  ],
  providers:[
   
  ],
  exports: [
    ...Modules,
    ...Components,
    ...Pipes
  ]
})
export class SharedModule { }
