import { environment } from 'src/environments/environment';

export function apiUrl(path){
    return environment.apiUrl + "/" + path;
}
export function toFormData(obj: any, form?: any, namespace?: any) {
    let fd = form || new FormData();
    let formKey;

    for (let property in obj) {
        if (obj.hasOwnProperty(property) && obj[property]) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File, use recursivity.
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            }
            else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                toFormData(obj[property], fd, formKey);
            } else { // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
}
export function getDefaultToken() {
    return 'Bearer 7QILAYitsFqCmJx5ugDTgmNSdUaJMVmNRus91moGaiJmXdfLr5egDYO0EerZqAoCm1J94hrhd9WQcxWT';
}

export function defaultToken() {
    return { Authorization: 'Bearer 7QILAYitsFqCmJx5ugDTgmNSdUaJMVmNRus91moGaiJmXdfLr5egDYO0EerZqAoCm1J94hrhd9WQcxWT'};
}

export function subObjectToParams(key: any, object: any) : string {
    return Object.keys(object).map((childKey) => {
       
        if (isJsObject(object[childKey]))
            return subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]);
        else
            return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`;
    }
    ).join('&');
}
export function isJsObject(object: any){
    let type: string = typeof object;
    return (type == 'object');
}
export function getTitle(title?: string): string {
    let text: any[] = [];
    if (title != undefined) {
        text.push(title)
    }
    text.push("Rajasthan Royals");
    return text.join(" - ");
}
