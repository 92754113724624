
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('src/app/layout/layout.module').then(m => m.LayoutModule)
  },
  { 
    path: 'contact-us.html', 
    redirectTo: '/contact' 
  },
  { 
    path: 'how-to-find-us.html', 
    redirectTo: '/contact' 
  },
  { 
    path: 'terms-and-conditions.html', 
    redirectTo: '/terms-of-use' 
  },
  { 
    path: 'privacy.html', 
    redirectTo: '/privacy' 
  },
  { 
    path: 'merchandise-terms-and-conditions.html', 
    redirectTo: '/terms-of-use'
  },
  { 
    path: 'how-to-find-us.html', 
    redirectTo: '/contact' 
  },
  { 
    path: 'partnership-opportunities.html', 
    redirectTo: '/partnership'
  },
  { 
    path: 'captains-club.html', 
    redirectTo: '/commercial'
  },
  { 
    path: 'diary/fixtures.html', 
    redirectTo: '/fixtures'
  },
  { 
    path: 'home', 
    redirectTo: '/'
  },
  { 
    path: 'index', 
    redirectTo: '/'
  },
  { 
    path: 'events', 
    redirectTo: '/event/whats-on'
  },
  { 
    path: 'match-tickets', 
    redirectTo: '/tickets'
  },
  { 
    path: 'season-tickets', 
    redirectTo: '/tickets'
  },
  { 
    path: 'buy-tickets', 
    redirectTo: '/tickets'
  },
  { 
    path: 'cricket/first-xi-fixtures.html', 
    redirectTo: '/fixtures'
  },
  { 
    path: 'cricket/2019/first-xi-players.html', 
    redirectTo: '/player'
  },
  { 
    path: '**',
    redirectTo: '/notfound'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
